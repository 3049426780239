<template>
  <div class="villages">
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        :md="paramID ? 6 : 12"
        v-if="!$vuetify.breakpoint.mobile || !paramID"
      >
        <list-items
          @paginationHandler="fetchList"
          :page="page"
          :listItems="listItems"
          :total="total"
          :updateKeyPage="updateKeyPage"
          :loading="loading"
          @searchVillage="searchVillage"
          @addVillage="addVillage"
          :md="paramID ? 6 : 3"
          @itemClicked="itemClicked"
          :pageSize="pageSize"
        ></list-items>
      </v-col>
      <v-col cols="12" :md="6">
        <item-body
          v-if="paramID"
          @reloadData="reloadData"
          :itemID="itemID"
          @collapse="collapse"
        ></item-body>
      </v-col>
    </v-row>
    <!-- village dialog -->
    <v-dialog v-model="addVillageDialog" max-width="750px">
      <village-dialog
        :key="addVillageDialog"
        @close="close"
        @reloadData="reloadData"
      ></village-dialog>
    </v-dialog>
  </div>
</template>

<script>
import ListItems from "@/components/villages/ListItems";
import ItemBody from "@/components/villages/ItemBody";
import VillageDialog from "@/components/dialogs/village/Index.vue";
export default {
  components: {
    ListItems,
    ItemBody,
    VillageDialog
  },
  data: () => ({
    paramID: "",
    listItems: [],
    itemID: undefined,
    total: 0,
    updateKeyPage: false,
    loading: true,
    source: undefined,
    page: 1,
    addVillageDialog: false,
    pageSize: 30
  }),
  watch: {
    "$route.params": {
      async handler() {
        this.paramID = this.$route.params.id;
        await this.fetchItemByID();
      }
    }
  },
  methods: {
    addVillage() {
      this.addVillageDialog = true;
    },
    close() {
      this.addVillageDialog = false;
    },
    reloadData() {
      this.fetchList(this.page);
      this.fetchItemByID();
    },
    async searchVillage(searchWord) {
      this.listItems = [];
      this.page = 1;
      this.searchWord = searchWord;
      await this.fetchList();
      if (!this.$vuetify.breakpoint.mobile) {
        if (!searchWord || searchWord.length == 0) {
          this.paramID = this.$route.params.id;
          this.fetchItemByID();
        } else if (this.listItems.length > 0) {
          this.paramID = this.listItems[0]._id;
          this.fetchItemByID();
        } else {
          this.paramID = undefined;
          this.itemID = undefined;
        }
      }
    },
    async fetchList(page) {
      this.loading = true;
      if (this.source) {
        this.source.cancel();
      }
      this.source = this.$axios.CancelToken.source();
      const currentSource = this.source;
      if (!page) {
        page = 1;
      }
      this.page = page;
      const response = await this.$axios.get(
        `/villages/all/new?${
          (this.searchWord && this.searchWord.length) > 0
            ? "searchWord=" + this.searchWord + "&"
            : ""
        }&pageSize=${this.pageSize}&pageNumber=${page}&sort=0`,
        { cancelToken: this.source.token }
      );
      if (response && response.data) {
        this.listItems = response.data.data;
        this.total = response.data.count;
      }
      if (currentSource === this.source) {
        this.loading = false;
      }
    },
    async fetchItemByID() {
      if (this.paramID) {
        let { data } = await this.$axios.get(`/villages/${this.paramID}`);
        if (data && data.length > 0) {
          this.itemID = data[0];
        }
      }
    },
    collapse() {
      this.$router.push("/villages");
      this.paramID = undefined;
    },
    itemClicked(item) {
      this.$router.push(`/villages/${item._id}`);
    }
  },
  async created() {
    this.paramID = this.$route.params.id;
    await this.fetchList();
    await this.fetchItemByID();
  }
};
</script>

<style lang="scss" scoped>
.villages {
  background: white;
  height: 100vh;
  overflow: hidden;
}
</style>
